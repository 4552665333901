import { getFilterDate } from './getFilterDate';

export const getPageByDate = (top: string, page: number, isMainPage: boolean) => {
  if (top === 'allTime') {
    return `/posts?\n
filters[isMainPage]=${isMainPage}&\n
filters[isHidden]=false&\n
sort[0]=likes:desc&\n
sort[1]=publishedAt:desc&\n
pagination[page]=${page}&\n
pagination[pageSize]=8&\n
populate[0]=postCoverPicture&\n
populate[1]=socialPicture&\n
populate[2]=relatedPosts&\n
populate[3]=author&\n
populate[4]=tags&\n
populate[5]=type&\n
populate[6]=postVideo&\n
populate[7]=author.avatar&\n
populate[8]=pollQuestion.pollOptions&\n
populate[9]=categories.icon`;
  }

  const date = getFilterDate(top);

  return `/posts?\n
filters[publishedAt][$gte]=${date}&\n
filters[isMainPage]=${isMainPage}&\n
filters[isHidden]=false&\n
sort[0]=likes:desc&\n
sort[1]=publishedAt:desc&\n
pagination[page]=${page}&\n
pagination[pageSize]=8&\n
populate[0]=postCoverPicture&\n
populate[1]=socialPicture&\n
populate[2]=relatedPosts&\n
populate[3]=author&\n
populate[4]=tags&\n
populate[5]=type&\n
populate[6]=postVideo&\n
populate[7]=author.avatar&\n
populate[8]=pollQuestion.pollOptions&\n
populate[9]=categories.icon`;
};
