export const getFilterDate = (interval: string) => {
  const currentDate = new Date();
  currentDate.setMinutes(0, 0, 0);

  if (interval === '24h') {
    currentDate.setHours(currentDate.getHours() - 24);
  }

  if (interval === '7d') {
    currentDate.setDate(currentDate.getDate() - 7);
  }

  if (interval === '30d') {
    currentDate.setDate(currentDate.getDate() - 30);
  }

  const formattedDate = currentDate.toISOString();

  return formattedDate;
};
