import { getFilterDate } from './getFilterDate';

export const getUserPostByDate = (top: string, page: number, userId: string) => {
  if (top === 'allTime') {
    return `/posts?\n
filters[author][id][$eq]=${userId}&\n
filters[isHidden]=false&\n
sort[0]=likes:desc&\n
pagination[page]=${page}&\n
pagination[pageSize]=8&\n
populate[0]=postCoverPicture&\n
populate[1]=socialPicture&\n
populate[2]=relatedPosts&\n
populate[3]=author&\n
populate[4]=tags&\n
populate[5]=type&\n
populate[6]=postVideo&\n
populate[7]=author.avatar`;
  }

  const date = getFilterDate(top);

  return `/posts?\n
filters[author][id][$eq]=${userId}&\n
filters[publishedAt][$gte]=${date}&\n
filters[isHidden]=false&\n
sort[0]=likes:desc&\n
pagination[page]=${page}&\n
pagination[pageSize]=8&\n
populate[0]=postCoverPicture&\n
populate[1]=socialPicture&\n
populate[2]=relatedPosts&\n
populate[3]=author&\n
populate[4]=tags&\n
populate[5]=type&\n
populate[6]=postVideo&\n
populate[7]=author.avatar`;
};
