import {
  IMessage,
  IPost,
  IPostLikeData,
  IPosts,
  ISurveyVote,
  IThrowIn,
  IThrowInData,
  IThrowInGalleryData,
  ImageData,
} from './interfaces';
import {
  mainPostsRoute,
  lockerPostsRoute,
  singlePostRoute,
  favouritePostsRoute,
  createPostsRoute,
  removeDislikeRoute,
  removeLikeRoute,
  addLikeRoute,
  addDislikeRoute,
  galleryPostsRoute,
  uploadBlogImage,
  messagesRoute,
  userPostsRoute,
  relatedPostsRoute,
  addVoteRoute,
  categoryPostsRoute,
} from './routes';

import { appInstance, authorizedUserInstance } from '../instances';

export const getMainPosts = async (page: number, tag: string, top: string) => {
  const response = await appInstance.get<IPosts>(mainPostsRoute(page, tag, top));

  return response;
};

export const getLockerPosts = async (page: number) => {
  const response = await appInstance.get<IPosts>(lockerPostsRoute(page));

  return response;
};

export const getSinglePost = async (id: string) => {
  const response = await appInstance.get<IPost>(singlePostRoute(id));

  return response;
};

export const getRelatedPosts = async (postsCount: number, postId: string) => {
  const response = await appInstance.get<IPosts>(relatedPostsRoute(postsCount, postId));

  return response;
};

export const getPostsByCategory = async (categoryId: string | string[] | undefined, page: number, top?: string) => {
  const response = await appInstance.get<IPosts>(categoryPostsRoute(categoryId, page, top));

  return response;
};

export const getUserPosts = async (page: number, userId: string, top?: string) => {
  const response = await appInstance.get<IPosts>(userPostsRoute(page, userId, top));

  return response;
};

export const getGalleryPosts = async (postType: string) => {
  if (postType !== 'gallery') return undefined;
  const response = await appInstance.get<IPosts>(galleryPostsRoute);

  return response;
};

export const getPostsByLikes = async (interval: string) => {
  const response = await appInstance.get<IPosts>(favouritePostsRoute(interval));

  return response;
};

export const getPostsByLikesOnClient = async (interval: string) => {
  const response = await appInstance.get<IPosts>(favouritePostsRoute(interval));

  return response;
};

export const getMessages = async () => {
  const response = await appInstance.get<IMessage>(messagesRoute);

  return response.data;
};

export const postAddLike = async (postId: number) => {
  const response = await appInstance.post<IPostLikeData>(addLikeRoute(postId));

  return response.data;
};

export const postAddDislike = async (postId: number) => {
  const response = await appInstance.post<IPostLikeData>(addDislikeRoute(postId));

  return response.data;
};

export const postRemoveLike = async (postId: number) => {
  const response = await appInstance.post<IPostLikeData>(removeLikeRoute(postId));
  return response.data;
};

export const postRemoveDislike = async (postId: number) => {
  const response = await appInstance.post<IPostLikeData>(removeDislikeRoute(postId));
  return response.data;
};

export const postVote = async (questionId: number, optionId: number) => {
  const response = await appInstance.post<ISurveyVote>(addVoteRoute(questionId, optionId));

  return response.data;
};

export const postThrowIn = async (data: IThrowInData, file: string | Blob, isVideo: boolean) => {
  const form = new FormData();
  const socialData = JSON.stringify(data);

  form.append('data', socialData);

  if (isVideo) {
    form.append('files.postVideo', file);
  }

  if (!isVideo) {
    form.append('files.postCoverPicture', file);
  }

  const response = await authorizedUserInstance.post<IThrowInData>(createPostsRoute, form);

  return response.data;
};

export const postThrowInGallery = async (
  data: IThrowInGalleryData[],
  executeAt: string | null,
  isMainPage: boolean,
) => {
  const addFieldsToFormData = (galleryData: IThrowInGalleryData[]) => {
    const form = new FormData();

    galleryData.forEach(
      (
        {
          title,
          tags,
          categories,
          additionalText,
          image,
          embedUrl,
          mediaSource,
          source,
          quoteAuthor,
          contentIsQuote,
          content,
          isVideo,
        },
        index,
      ) => {
        const state = {
          title,
          tags,
          categories,
          additionalText,
          type: embedUrl ? 3 : 2,
          embedMediaUrl: embedUrl,
          mediaSource,
          source,
          content,
          contentIsQuote,
          quoteAuthor,
          executeAt: index === 0 ? executeAt : null,
          isMainPage: index === 0 ? isMainPage : null,
        };
        const fieldName = index === 0 ? 'data' : `data${index}`;
        const fieldData = JSON.stringify(state);
        form.append(fieldName, fieldData);

        if (isVideo) {
          const pictureFieldName = index === 0 ? 'files.postCoverPicture' : `files.postVideo${index}`;
          if (!embedUrl) form.append(pictureFieldName, image);
        }
        if (!isVideo) {
          const pictureFieldName = index === 0 ? 'files.postCoverPicture' : `files.postCoverPicture${index}`;
          if (!embedUrl) form.append(pictureFieldName, image);
        }
      },
    );

    return form;
  };

  const form = addFieldsToFormData(data);

  const response = await authorizedUserInstance.post<IThrowInData>(`${createPostsRoute}/create-multiple`, form);

  return response.data;
};

export const postTag = async (data: IThrowInData) => {
  const form = new FormData();
  const socialData = JSON.stringify(data);

  form.append('data', socialData);

  const response = await authorizedUserInstance.post<IThrowIn>(createPostsRoute, form);

  return response;
};

export const postBlogImage = async (file: Blob) => {
  const form = new FormData();
  form.append('files', file);

  const response = await authorizedUserInstance.post<ImageData[]>(uploadBlogImage, form);

  return response;
};

export const putMainPagePost = async (id: number, isMainPage: boolean) => {
  const isMainPageData = {
    data: {
      isMainPage,
    },
  };

  const response = await authorizedUserInstance.put(`/posts/${id}`, isMainPageData);

  return response;
};

export const deletePost = async (id: number) => {
  const response = await authorizedUserInstance.delete(`/posts/${id}`);

  return response;
};
