import { getFilterDate } from './getFilterDate';

export const getSidebarByDate = (interval: string) => {
  if (interval === 'allTime') {
    return `/posts?\n
filters[isMainPage]=true&\n
filters[isHidden]=false&\n
sort[0]=likes:desc&\n
pagination[page]=1&\n
pagination[pageSize]=5&\n
populate[0]=postCoverPicture`;
  }

  const date = getFilterDate(interval);

  return `/posts?\n
filters[isMainPage]=true&\n
filters[isHidden]=false&\n
filters[publishedAt][$gte]=${date}&\n
sort[0]=likes:desc&\n
pagination[page]=1&\n
pagination[pageSize]=5&\n
populate[0]=postCoverPicture`;
};
