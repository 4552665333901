import { getCategoryByDate } from '@/helpers/getCategoryByDate';
import { getPageByDate } from '@helpers/getPageByDate';
import { getSidebarByDate } from '@helpers/getSidebarByDate';
import { getUserPostByDate } from '@helpers/getUserPostByDate';
import { getTagByDate } from '@helpers/getTagByDate';

export const removeDislikeRoute = (id: number) => `/posts/${id}/remove-dislike`;
export const removeLikeRoute = (id: number) => `/posts/${id}/remove-like`;
export const addDislikeRoute = (id: number) => `/posts/${id}/dislike`;
export const addLikeRoute = (id: number) => `/posts/${id}/like`;
export const addVoteRoute = (questionId: number, optionId: number) =>
  `/poll-question/${questionId}/poll-option/${optionId}/vote`;
export const messagesRoute = `/messages?populate=*`;
export const createPostsRoute = '/posts';
export const uploadBlogImage = '/upload';
export const createTagsRoute = '/tags';

export const mainPostsRoute = (page: number, tag?: string, top?: string) => {
  // filter by top and tag
  if (tag && top) {
    const filteredPostUrl = getTagByDate(top, tag, page);
    return filteredPostUrl;
  }

  // filter by tags
  if (tag) {
    return `/posts?\n
filters[isMainPage]=true&\n
filters[isHidden]=false&\n
filters[tags][name]=${tag}&\n
sort[0]=publishedAt:desc&\n
pagination[page]=${page}&\n
pagination[pageSize]=8&\n
populate[0]=postCoverPicture&\n
populate[1]=socialPicture&\n
populate[2]=relatedPosts&\n
populate[3]=author&\n
populate[4]=tags&\n
populate[5]=type&\n
populate[6]=postVideo&\n
populate[7]=executeAt&\n
populate[8]=author.avatar&\n
populate[9]=pollQuestion.pollOptions&\n
populate[10]=categories.icon`;
  }

  // filter by top and time interval
  if (top) {
    const filteredPostUrl = getPageByDate(top, page, true);
    return filteredPostUrl;
  }

  // all posts on main page
  return `/posts?\n
filters[isMainPage]=true&\n
filters[isHidden]=false&\n
sort[0]=publishedAt:desc&\n
pagination[page]=${page}&\n
pagination[pageSize]=8&\n
populate[0]=postCoverPicture&\n
populate[1]=socialPicture&\n
populate[2]=relatedPosts&\n
populate[3]=author&\n
populate[4]=tags&\n
populate[5]=type&\n
populate[6]=postVideo&\n
populate[7]=executeAt&\n
populate[8]=author.avatar&\n
populate[9]=pollQuestion.pollOptions&\n
populate[10]=categories.icon`;
};

export const lockerPostsRoute = (page: number) => {
  return `/posts?\n
filters[isMainPage]=false&\n
filters[isHidden]=false&\n
sort[0]=publishedAt:desc&\n
pagination[page]=${page}&\n
pagination[pageSize]=8&\n
populate[0]=postCoverPicture&\n
populate[1]=socialPicture&\n
populate[2]=relatedPosts&\n
populate[3]=author&\n
populate[4]=tags&\n
populate[5]=type&\n
populate[6]=postVideo&\n
populate[7]=executeAt&\n
populate[8]=author.avatar&\n
populate[9]=pollQuestion.pollOptions&\n
populate[10]=categories.icon`;
};

export const favouritePostsRoute = (interval: string) => {
  const favouritePosts = getSidebarByDate(interval);

  return favouritePosts;
};

export const singlePostRoute = (id: string) => `/posts/${id}?\n
populate[0]=postCoverPicture&\n
populate[1]=socialPicture&\n
populate[3]=author&\n
populate[4]=tags&\n
populate[5]=type&\n
populate[6]=relatedPosts&\n
populate[7]=postVideo&\n
populate[8]=relatedPosts.postCoverPicture&\n
populate[9]=relatedPosts.postVideo&\n
populate[10]=author.avatar&\n
populate[11]=pollQuestion.pollOptions&\n
populate[12]=categories.icon`;

export const galleryPostsRoute = `/posts?\n
sort[0]=publishedAt:desc&\n
pagination[page]=1&\n
pagination[pageSize]=6&\n
filters[type][id][$eq]=4&\n
populate[0]=postCoverPicture&\n
populate[1]=relatedPosts`;

export const userPostsRoute = (page: number, userId: string, top?: string) => {
  // filter by top and time interval
  if (top) {
    const filteredPostUrl = getUserPostByDate(top, page, userId);
    return filteredPostUrl;
  }

  // all user's posts
  return `/posts?\n
filters[author][id][$eq]=${userId}&\n
filters[isHidden]=false&\n
sort[0]=publishedAt:desc&\n
pagination[page]=${page}&\n
pagination[pageSize]=8&\n
populate[0]=postCoverPicture&\n
populate[1]=socialPicture&\n
populate[2]=relatedPosts&\n
populate[3]=author&\n
populate[4]=tags&\n
populate[5]=type&\n
populate[6]=postVideo&\n
populate[7]=author.avatar&\n
populate[8]=pollQuestion.pollOptions&\n
populate[9]=categories.icon`;
};

export const relatedPostsRoute = (postsCount: number, postId: string) => `/posts?\n
filters[id][$ne]=${postId}&\n
filters[isMainPage]=true&\n
filters[isHidden]=false&\n
sort[0]=publishedAt:desc&\n
pagination[page]=1&\n
pagination[pageSize]=${postsCount}&\n
populate[0]=postCoverPicture&\n
populate[1]=socialPicture&\n
populate[2]=relatedPosts&\n
populate[3]=author&\n
populate[4]=tags&\n
populate[5]=type&\n
populate[6]=postVideo&\n
populate[7]=executeAt&\n
populate[8]=author.avatar&\n
populate[9]=pollQuestion.pollOptions&\n
populate[10]=categories.icon`;

export const categoryPostsRoute = (categoryId: string | string[] | undefined, page: number, top?: string) => {
  // filter by top and time interval
  if (top) {
    const filteredPostUrl = getCategoryByDate(categoryId, top, page);
    return filteredPostUrl;
  }

  return `/posts?\n
filters[isMainPage]=true&\n
filters[isHidden]=false&\n
filters[categories][id][$in]=${categoryId}&\n
sort[0]=publishedAt:desc&\n
pagination[page]=${page}&\n
pagination[pageSize]=8&\n
populate[0]=postCoverPicture&\n
populate[1]=socialPicture&\n
populate[2]=relatedPosts&\n
populate[3]=author&\n
populate[4]=tags&\n
populate[5]=type&\n
populate[6]=postVideo&\n
populate[7]=executeAt&\n
populate[8]=author.avatar&\n
populate[9]=pollQuestion.pollOptions&\n
populate[10]=categories.icon`;
};
